import { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react';
import './button.scss';
import { ButtonBaseProps, useButtonCss } from './useButtonCss';

export type ButtonProps = ButtonBaseProps & ButtonHTMLAttributes<HTMLButtonElement> & {children?: JSX.Element|JSX.Element[];}

export const Button = forwardRef((props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {

    const { css, label, Icon, rest } = useButtonCss(props, 'button');

    return (
        <button
            className={css}
            {...rest}
            ref={ref}
            type={rest.type ?? 'button'}
        >
            {Icon && <Icon/>}
            {
                label &&
                <p
                    className={'button__label'}
                >
                    {label}
                </p>
            }
            {props.children}
        </button>
    );
});
