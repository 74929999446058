import { globalConfig } from '../../config/globalConfig';
import { ApiRequestMethodEnum } from '../../domain/enum/apiRequestMethodEnum';
import FileMeta from '../../domain/models/fileMeta';
import { BaseApi } from '../baseApi';

const { POST } = ApiRequestMethodEnum;

export class FileStorageApi extends BaseApi {
    static DEFAULT_URL = `${globalConfig.api}/file-storage/`;

    /**
     * Скачать файл
     * @param {*} fileId
     * @param {*} format
     */
    static download = (fileId: string): void => {
        let url = `${this.DEFAULT_URL}${fileId}/download`;

        const a: HTMLAnchorElement = document.createElement('a');
        a.href = url;
        a.download = '';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    /**
     * Загрузить файл на сервер
     * @param {*} file
     */
    static upload = (file: File): Promise<FileMeta[] | null> => {
        const formData = new FormData();
        formData.append('data', file, file.name);

        const config = {
            headers: { 'content-type': 'multipart/form-data' },
        };

        return this.apiResource('upload', POST, formData, config);
    };
}