import { DOMAttributes, DragEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFileSelect, UseFileSelect } from './useFileSelect';

export namespace UseDropZone {
    export type AcceptConfig = UseFileSelect.AcceptConfig;

    export type Params = {
        /**
         * Обработчик для выбранных файлов.
         */
        handleDrop?: (files: File[]) => void,

        /**
         * Если `true`, то файлы буду добавляться только перетаскиванием.
         */
        dropOnly?: boolean,

        /**
         * Если `false`, то файлы, добавленные перетаскиванием, не будут проверяться на соответствие условиям `accept`.
         */
        filterDrop?: boolean,

        /**
         * Если `true`, то работа хука приостанавливается.
         */
        disabled?: boolean,

        /**
         * Разрешить выбирать несколько файлов.
         */
        multiple?: boolean,

        /**
         * Задает параметры для фильтрации файлов по их расширению и их mime type.
         */
        accept?: AcceptConfig
    }

    export type Listeners = Required<Pick<DOMAttributes<Element>,
        'onDragEnter' | 'onDragLeave' | 'onDragOver' | 'onDrop' | 'onClick'>>

    export type Return = {
        /**
         * `true` если перетаскиваемый объект находится в зоне контейнера, `false` иначе.
         */
        isActive: boolean,

        /**
         * true, если перетаскиваемый объект находится в зоне окна, false иначе.
         */
        isWindowActive: boolean,

        /**
         * Коллекция listeners на которые нужно подписать контейнер для корректной работы хука.
         */
        listeners: Listeners,

        /**
         * Вызывает browser api для выбора файла, игнорирует `dropOnly` флаг.
         */
        selectFiles: () => void
    }
}

export function useDropZone(params?: UseDropZone.Params): UseDropZone.Return {
    const multiple = useMemo((): boolean => params?.multiple ?? true, [ params?.multiple ]);
    const selectFile = useFileSelect({
        multiple: multiple,
        handleSelect: params?.handleDrop,
        accept: params?.accept,
    });
    const [ isActive, setIsActive ] = useState(false);
    const [ isWindowActive, setIsWindowActive ] = useState(false);
    const windowDragCounter = useRef(0);
    const dragCounter = useRef(0);

    const onDragEnter = useCallback(() => setIsActive(++dragCounter.current > 0), []);
    const onDragLeave = useCallback(() => setIsActive(--dragCounter.current > 0), []);
    const onDragOver = useCallback((event: DragEvent) => event.preventDefault(), []);

    useEffect(() => {
        const onWindowDragEnter = () => setIsWindowActive(++windowDragCounter.current > 0);
        const onWindowDragLeave = () => setIsWindowActive(--windowDragCounter.current > 0);
        const onWindowDragOver = (event: Event) => event.preventDefault();

        const onWindowDrop = () => {
            setIsWindowActive(false);
            windowDragCounter.current = 0;
        };

        window.addEventListener('dragenter', onWindowDragEnter);
        window.addEventListener('dragleave', onWindowDragLeave);
        window.addEventListener('dragover', onWindowDragOver);
        window.addEventListener('drop', onWindowDrop);

        return () => {
            window.removeEventListener('dragenter', onWindowDragEnter);
            window.removeEventListener('dragleave', onWindowDragLeave);
            window.removeEventListener('dragover', onWindowDragOver);
            window.removeEventListener('drop', onWindowDrop);
        };
    }, []);

    const filterDrop = useCallback((fileList: FileList): File[] => {
        const files = Array.from(fileList);
        const types = params?.accept?.mimeTypes?.filter(it => /\S/.test(it))
                            .map(it => it.toLowerCase());
        const ext = params?.accept?.extensions?.filter(it => /\S/.test(it))
                          .map(it => it.toLowerCase());

        if (params?.filterDrop === false || (!ext?.length && !types?.length)) return files;

        const extFilter = ext ? new RegExp(`^.*\\.(${ext.join('|')})$`) : undefined;
        const typesFilter = types ? new RegExp(`^(${types.join('|')})/.*$`) : undefined;

        return files.filter(file => {
            return extFilter?.test(file.name) || typesFilter?.test(file.type);
        });
    }, [ params?.accept?.extensions, params?.accept?.mimeTypes, params?.filterDrop ]);

    const onDrop = useCallback((event: DragEvent) => {
        event.preventDefault();
        event.stopPropagation();

        setIsActive(false);
        setIsWindowActive(false);
        dragCounter.current = 0;
        windowDragCounter.current = 0;

        if (params?.disabled) return;

        if (event.dataTransfer?.files) {
            const filteredDrop = filterDrop(event.dataTransfer.files);
            if (filteredDrop) {
                const filesToHandle = multiple
                    ? filteredDrop
                    : [ filteredDrop[0] ];
                params?.handleDrop?.(filesToHandle);
            }
        }
    }, [ params?.disabled, params?.handleDrop, filterDrop ]);

    const selectFiles = useCallback(() => {
        if (params?.disabled) return;
        selectFile();
    }, [ params?.disabled, params?.handleDrop ]);

    const onClick = useCallback(() => {
        if (window.getSelection()?.type === 'Range') return;
        if (params?.dropOnly) return;
        selectFiles();
    }, [ params?.dropOnly, selectFiles ]);

    return {
        isActive,
        isWindowActive,
        listeners: {
            onDragEnter,
            onDragLeave,
            onDragOver,
            onDrop,
            onClick,
        },
        selectFiles,
    };
}
