import './appHeader.scss';

export function AppHeader() {

    return (
        <header
            className={'main-header'}
        >
            
        </header>
    );
};
