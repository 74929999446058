import clsx from 'clsx';
import NegoFile from '../../../../domain/models/negoFile';
import './listFileElement.scss';
import {useCallback, useMemo} from 'react';
import {
    STATUS_NEGO_FILE,
} from '../../../../domain/enum/statusNegoFile';
import {Table} from '@mantine/core';
import {format} from 'date-fns';

export interface ListFileElementProps {
    data: NegoFile;
    toggleSelection: (id: string) => void;
    isSelected?: boolean;
}

export function ListFileElement({
    data,
    toggleSelection,
    isSelected,
}: ListFileElementProps) {
    const getColor = useCallback(() => {
        switch (data.status.name) {
            case STATUS_NEGO_FILE.DONE.name: {
                return 'green';
            }
            case STATUS_NEGO_FILE.PROCESSING.name: {
                return 'yellow';
            }
            default: {
                return 'gray';
            }
        }
    }, [data]);

    const isBlockSelect: boolean = useMemo(
        () => data.status !== STATUS_NEGO_FILE.DONE,
        [data.status],
    );

    const onSelect = useCallback(() => {
        if (!isBlockSelect) {
            toggleSelection(data.id);
        }
    }, [isBlockSelect, toggleSelection, data]);

    return (
        <Table.Tr
            className={clsx(
                'list-file-element__title',
                'list-file-element',
                {'list-file-element--selected': isSelected},
                {'list-file-element--block': isBlockSelect},
            )}
            onClick={onSelect}
        >
            <Table.Td>
                <p className={'list-file-element__text'}>
                    {data.content ? data.content.name : 'Нет'}
                </p>
            </Table.Td>
            <Table.Td>
                <p className={'list-file-element__text'}>{data.duration}</p>
            </Table.Td>
            <Table.Td>
                <p className={'list-file-element__text'}>
                    {data.tags.join(', ')}
                </p>
            </Table.Td>
            <Table.Td>
                <p className={'list-file-element__text'}>
                    {data.createDate
                        ? format(
                              Date.parse(data.createDate),
                              'yyyy.MM.dd HH:mm',
                          )
                        : 'Нет'}
                </p>
            </Table.Td>
            <Table.Td>
                <span>
                    {data.negotiators.map((value, index) => (
                        <>
                            {`${value.name}: ${value.result}`} <br />
                        </>
                    ))}
                </span>
            </Table.Td>
            <Table.Td
                className={clsx(
                    `list-file-element__status--${getColor()}`,
                    'list-file-element__text',
                )}
            >
                {data.status.value}
            </Table.Td>
        </Table.Tr>
    );
}
