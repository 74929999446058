import { globalConfig } from '../../config/globalConfig';
import { BaseApi } from '../baseApi';
import { FilterParams } from './params/filterParams';
import { PaggableParams } from './params/paggableParams';
import { CollectionDTO } from '../../domain/interfaces/collectionDTO';
import NegoFile, { NegoFileDto, NegoFileDtoType } from '../../domain/models/negoFile';
import { AddToProcessingParams } from './params/addToProcessingParams';
import { ApiRequestMethodEnum } from '../../domain/enum/apiRequestMethodEnum';
import TagNegoFile, { TagNegoFileDto, TagNegoFileDtoType } from '../../domain/models/tagNegoFile';
import Negotiator, { NegotiatorDto, NegotiatorDtoType } from '../../domain/models/negotiator';

export class NegoApi extends BaseApi {
    static DEFAULT_URL = `${globalConfig.api}/nego-files/`;

    static getAllTags = (): Promise<TagNegoFile[]> => {
        return this.arrayApiResource<TagNegoFileDto, TagNegoFile>(
            {
                url: `tags/list`,
                model: TagNegoFile,
                modelType: TagNegoFileDtoType,
            }
        );
    }

    static getAllNegotiators = (): Promise<Negotiator[]> => {
        return this.arrayApiResource<NegotiatorDto, Negotiator>(
            {
                url: `negotiators/list`,
                model: Negotiator,
                modelType: NegotiatorDtoType,
            }
        );
    }

    static getAllFiles = (paggeable?: PaggableParams, filter?: FilterParams): Promise<CollectionDTO<NegoFile>> => {
        return this.collectionApiResource<NegoFileDto, NegoFile>(
            {
                url: `page`,
                model: NegoFile,
                modelType: NegoFileDtoType,
                params: { ...paggeable, ...filter }
            }
        );
    }

    static addToProcessing = (params: AddToProcessingParams): Promise<NegoFile | null> => {
        return this.modelApiResource<NegoFileDto, NegoFile>({
            url: 'add-to-processing',
            method: ApiRequestMethodEnum.POST,
            model: NegoFile,
            modelType: NegoFileDtoType,
            params: params,
        });
    }
}