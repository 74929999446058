import {Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import {AppLoader} from '../components/appLoader/appLoader';
import {HomePage} from '../pages/homePage';
import {AppWrapper} from '../components/contentPageWrapper/appWrapper';

export function AppRoutes() {
    return (
        <Suspense fallback={<AppLoader />}>
            <Routes>
                <Route element={<AppWrapper />}>
                    <Route
                        index={true}
                        path={'/:id'}
                        element={<HomePage />}
                    />
                    <Route
                        index={true}
                        element={<HomePage />}
                    />
                </Route>
            </Routes>
        </Suspense>
    );
}
