import { useCallback, useEffect, useMemo, useRef } from 'react';

export namespace UseFileSelect {
    export type AcceptConfig = {
        /**
         * Разрешение указывается без точки
         * Регистр не учитывается
         * @example
         * [ 'png', 'jpg' ]
         */
        extensions?: string[],

        /**
         * Тип указывается до начала обобщения
         * Регистр не учитывается
         * @example
         * [ 'image', 'video' ]
         */
        mimeTypes?: string[],
    }

    export type Params = {
        /**
         * Обработчик для выбранных файлов.
         * @param files
         */
        handleSelect?: (files: File[]) => void,

        /**
         * Задает параметры для фильтрации файлов по их расширению и их mime type.
         */
        accept?: AcceptConfig

        /**
         * Разрешить выбирать несколько файлов.
         */
        multiple?: boolean
    }
}

export function useFileSelect(params?: UseFileSelect.Params) {

    const accept = useMemo((): string => {
        const extensions = params?.accept?.extensions?.map(it => '.' + it) ?? [];
        const mimeTypes = params?.accept?.mimeTypes?.map(it => it + '/*') ?? [];
        return mimeTypes.concat(extensions).join(', ');
    }, [ params?.accept ]);

    const multiple = useMemo((): boolean => params?.multiple ?? false, [ params?.multiple ]);

    const inputRef = useRef((() => {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = multiple;
        input.accept = accept;
        return input;
    })());

    useEffect(() => {
        inputRef.current.multiple = multiple;
    }, [ multiple ]);

    useEffect(() => {
        inputRef.current.accept = accept;
    }, [ accept ]);

    useEffect(() => {
        function handleSelect(event: Event) {
            const target = event.target as HTMLInputElement;
            if (target.files) {
                const files = Array.from(target.files);
                const types = params?.accept?.mimeTypes?.filter(it => /\S/.test(it))
                                    .map(it => it.toLowerCase());
                const ext = params?.accept?.extensions?.filter(it => /\S/.test(it))
                                  .map(it => it.toLowerCase());
                const extFilter = ext ? new RegExp(`^.*\\.(${ext.join('|')})$`) : undefined;
                const typesFilter = types ? new RegExp(`^(${types.join('|')})/.*$`) : undefined;

                console.log(extFilter);
                console.log(typesFilter);

                params?.handleSelect?.(files.filter(file => {
                    return extFilter?.test(file.name) || typesFilter?.test(file.type) || (!extFilter && !typesFilter);
                }));
                inputRef.current.value = '';
            }
        }

        inputRef.current.addEventListener('change', handleSelect);

        return () => {
            inputRef.current.removeEventListener('change', handleSelect);
        }
    }, []);

    return useCallback(() => inputRef.current.click(), []);
}
