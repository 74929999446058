import NegoFile from '../../domain/models/negoFile';
import {ListFileElement} from './components/listFileElement/listFileElement';
import {TableHeader} from './components/tableHeader/tableHeader';
import './listFilesComponent.scss';
import {useGoToState} from '../../hooks/useGoToState';
import {ScrollArea, Table} from '@mantine/core';
import { useMemo, useState} from 'react';
import { compareAsc } from 'date-fns';
import clsx from 'clsx';

export interface ListFilesComponentProps {
    data: NegoFile[];
    toggleSelection: (id: string) => void;
    selectFiles: string[];
}

export function ListFilesComponent({
    data,
    selectFiles,
    ...rest
}: ListFilesComponentProps) {
    const {navigate} = useGoToState();
    const [sortParam, setSortParam] = useState<keyof NegoFile | null>(null);
    const [isReverseSort, setIsReverseSort] = useState<boolean>(false);
    const [scrolled, setScrolled] = useState<boolean>(false);

    const sortData = useMemo(() => {
        return sortParam !== null ? sort(data, sortParam, isReverseSort) : data;
    }, [sortParam, isReverseSort, data]);

    function sort(data: NegoFile[], param: keyof NegoFile, reverse: boolean) {
        const list = data.sort((a, b) => {
            if(param === 'createDate') {
                return compareAsc(Date.parse(a.createDate), Date.parse(b.createDate));
            }

            const oneValue = getStringParam(a, param);
            const twoValue = getStringParam(b, param);

            return oneValue.localeCompare(twoValue);
        });

        return reverse ? list.reverse() : list;
    }

    function getStringParam(value: NegoFile, param: keyof NegoFile): string {
        switch(param) {
            case 'content': {
                return value.content?.name ?? '';
            }
            case 'negotiators': {
                return value.negotiators.map(file => file.name).join();
            }
            case 'tags': {
                return value.tags.join();
            }
            case 'status': {
                return value.status.value;
            }
            default: {
                return '';
            }
        }
    }

    function toggleSort(newSort: keyof NegoFile) {
        if (newSort === sortParam) {
            setIsReverseSort((value) => !value);
        } else {
            setIsReverseSort(false);
        }
        setSortParam(newSort);
    }

    return (
        <ScrollArea 
            onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
            className={'list-files-component__scroll'}
        >
            <Table
                className={'list-files-component'}
                horizontalSpacing='md'
                verticalSpacing='xs'
                layout='fixed'
            >
                <Table.Thead 
                    className={clsx(
                        'list-files-component__table-header', 
                        {'list-files-component__table-scrolled': scrolled })}
                    >
                    <Table.Tr>
                        <TableHeader
                            onSort={() => toggleSort('content')}
                            reversed={isReverseSort}
                            sorted={sortParam === 'content'}
                        >
                            Файл
                        </TableHeader>
                        <TableHeader
                            onSort={() => toggleSort('duration')}
                            reversed={isReverseSort}
                            sorted={sortParam === 'duration'}
                        >
                            Продолжительность
                        </TableHeader>
                        <TableHeader
                            onSort={() => toggleSort('tags')}
                            reversed={isReverseSort}
                            sorted={sortParam === 'tags'}
                        >
                            Теги
                        </TableHeader>
                        <TableHeader
                            onSort={() => toggleSort('createDate')}
                            reversed={isReverseSort}
                            sorted={sortParam === 'createDate'}
                        >
                            Дата добавления
                        </TableHeader>
                        <TableHeader
                            onSort={() => toggleSort('negotiators')}
                            reversed={isReverseSort}
                            sorted={sortParam === 'negotiators'}
                        >
                            Участники
                        </TableHeader>
                        <TableHeader
                            onSort={() => toggleSort('status')}
                            reversed={isReverseSort}
                            sorted={sortParam === 'status'}
                        >
                            Статус
                        </TableHeader>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {sortData.map((value) => (
                        <ListFileElement
                            data={value}
                            isSelected={!!selectFiles.find(idSelect => idSelect===value.id)}
                            key={value.id}
                            {...rest}
                        />
                    ))}
                </Table.Tbody>
            </Table>
        </ScrollArea>
    );
}
