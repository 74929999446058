import './appFooter.scss';


export function AppFooter() {

    return (
        <footer className={'footer'}>
            
        </footer>
    );
}
