import { NameValuePair } from '../../types/nameValuePair';
import { createEnum } from '../../utils/typesUtils';

export enum StatusNegoFileEnum {
    QUEUE = 'QUEUE',
    PROCESSING = 'PROCESSING',
    DONE = 'DONE',
    NO_STATUS = 'NO_STATUS',
    ERROR = 'ERROR'
}

export interface StatusNegoFile {
    [value: string]: NameValuePair;

    QUEUE: NameValuePair;
    PROCESSING: NameValuePair;
    DONE: NameValuePair;
    NO_STATUS: NameValuePair;
    ERROR: NameValuePair;
}

export const STATUS_NEGO_FILE: StatusNegoFile = {
    QUEUE: {
        name: StatusNegoFileEnum.QUEUE,
        value: 'Очередь',
    },
    PROCESSING: {
        name: StatusNegoFileEnum.PROCESSING,
        value: 'В процессе',
    },
    DONE: {
        name: StatusNegoFileEnum.DONE,
        value: 'Готово'
    },
    NO_STATUS: {
        name: StatusNegoFileEnum.NO_STATUS,
        value: 'Нет статуса'
    },
    ERROR: {
        name: StatusNegoFileEnum.ERROR,
        value: 'Ошибка',
    }
}

export const StatusNegoFileEnumDtoType = createEnum<StatusNegoFileEnum>(StatusNegoFileEnum, 'StatusNegoFileEnum');