import { useLocation, useNavigate } from 'react-router-dom';

/**
 * аналог goToState в globalStore
 * для полных возможностей использовать useNavigate и useLocation
 */
export function useGoToState() {
    const navigate = useNavigate();
    const location = useLocation();

    /**
     * Перенаправление на уровень выше в url
     */
    function goUp(times = 1) {
        navigate('..'.repeat(times));
    }

    return {
        goUp,
        location, navigate,
    };
}
