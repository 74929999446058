import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {api} from '../config/api';
import {globalConfig} from '../config/globalConfig';
import {ApiRequestMethodEnum} from '../domain/enum/apiRequestMethodEnum';
import {BaseApiRequestConfig} from '../domain/interfaces/baseApiRequestConfig';
import {CollectionDTO} from '../domain/interfaces/collectionDTO';
import {
    checkElement,
    checkElementsArray,
    checkElementsCollection,
} from '../utils/typesUtils';

const {GET} = ApiRequestMethodEnum;

export class BaseApi {
    static DEFAULT_URL = globalConfig.api;
    static api = api;

    static apiResource<T>(
        url: string,
        method: ApiRequestMethodEnum = GET,
        params: any = {},
        config: AxiosRequestConfig = {},
    ): Promise<T | null> {
        const fullUrl = `${this.DEFAULT_URL}${url}`;

        const request =
            method === GET
                ? api.get(fullUrl, {...config, params})
                : api.post(fullUrl, params, config); // для POST-запроса параметры отправляются как body

        return request.then((res: AxiosResponse<T>) => res.data ?? null);
    }

    /**
     * Получить одну сущность
     * @param baseRequestConfig
     */
    static modelApiResource<Dto, Model>(
        baseRequestConfig: BaseApiRequestConfig,
    ): Promise<Model | null> {
        const {
            url = '',
            method = GET,
            params = {},
            config,
            headers,
            model,
            modelType,
            serializeParams,
        } = baseRequestConfig;

        return this.apiResource<Dto | null>(
            url,
            method,
            params,
            this.makeConfig(config, headers, serializeParams),
        ).then((dto: Dto | null) =>
            modelType
                ? checkElement<Model>(modelType, model, dto)
                : dto
                ? new model(dto)
                : null,
        );
    }

    /**
     * Получить массив сущностей
     * @param baseRequestConfig
     */
    static arrayApiResource<Dto, Model>(
        baseRequestConfig: BaseApiRequestConfig,
    ): Promise<Model[]> {
        const {
            url = '',
            method = GET,
            params = {},
            config,
            headers,
            model,
            modelType,
            serializeParams,
        } = baseRequestConfig;

        return this.apiResource<Dto[] | null>(
            url,
            method,
            params,
            this.makeConfig(config, headers, serializeParams),
        ).then((items: Dto[] | null) =>
            modelType
                ? checkElementsArray<Model>(modelType, model, items ?? [])
                : items?.map((it) => new model(it)) ?? [],
        );
    }

    /**
     * Получить пейджинированный массив сущностей
     * @param baseRequestConfig
     */
    static collectionApiResource<Dto, Model>(
        baseRequestConfig: BaseApiRequestConfig,
    ): Promise<CollectionDTO<Model>> {
        const {
            url = '',
            method = GET,
            params = {},
            config,
            headers,
            model,
            modelType,
            serializeParams,
        } = baseRequestConfig;

        return this.apiResource<CollectionDTO<Dto> | null>(
            url,
            method,
            params,
            this.makeConfig(config, headers, serializeParams),
        ).then((res) => {
            const dto = res ?? {items: [], totalCount: 0};

            return modelType
                ? checkElementsCollection<Model>(modelType, model, dto)
                : {
                      items: dto.items.map((el) => new model(el)),
                      totalCount: dto.totalCount,
                  };
        });
    }

    private static makeConfig(
        config?: AxiosRequestConfig,
        headers?: any,
        serializeParams?: boolean,
    ): AxiosRequestConfig {
        return {
            ...config,
            headers,
            paramsSerializer: serializeParams
                ? {serialize: (params) => new URLSearchParams(params).toString()}
                : undefined,
        };
    }
}
