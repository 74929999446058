import clsx from 'clsx';
import { GroupBase } from 'react-select/dist/declarations/src/types';
import './searchSelect.scss';
import ReactSelect from 'react-select';
import { SearchSelectAndDefaultProps } from './types/searchSelectProps';

export function SearchSelectAndDefault<Option = unknown, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(props: SearchSelectAndDefaultProps<Option, IsMulti, Group>) {
    const { label, className, error, ...rest } = props;

    return (
        <div
            className={clsx(
                'search-select-wrapper',
                className && `${className}-wrapper`
            )}
            data-with-error={!!error}
        >
            {
                !!label &&
                <span className={clsx('search-select-wrapper__label', 'label-2')}>
                    {label}
                </span>
            }
            <ReactSelect
                className={clsx(
                    className,
                    'body-1',
                    'search-select',
                    error && 'search-select--error',
                )}
                classNamePrefix={'search-select'}
                placeholder={'Начните вводить'}
                noOptionsMessage={() => 'Добавить новый'}
                loadingMessage={() => 'Загрузка...'}
                theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        neutral50: '#c2c2c2',
                        primary: '#c2c2c2',
                        primary25: '#f2f2f2',
                        primary50: '#f2f2f2',
                        primary75: '#f2f2f2',
                    }
                })}
                {...rest}
            />
        </div>
    );
}
