import { useCallback, useEffect, useState } from 'react';
import {NegoApi} from '../../../api/nego/negoApi';
import {ModalWindow, ModalWindowProps} from '../../../components/modalWindow/modalWindow';
import './addNewFileModal.scss';
import FileMeta from '../../../domain/models/fileMeta';
import { AddFileInput } from './components/addFileInput/addFileInput';
import TagNegoFile from '../../../domain/models/tagNegoFile';
import { usePromiseWithLoadingContext } from '../../../hooks/usePromiseWithLoadingContext';
import { Button } from '../../../components/button/button';
import { toast } from 'react-toastify';
import { AddTagInput } from './components/addTagInput/addTagInput';
import { SelectSpickers } from './components/selectSpickers/selectSpickers';
import Negotiator from '../../../domain/models/negotiator';

export interface AddNewFileModalProps extends ModalWindowProps {
    updateData: () => void;
}

export function AddNewFileModal({ updateData, ...rest}: AddNewFileModalProps) {

    const [files, setFiles] = useState<FileMeta[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [negotiators, setNegotiators] = useState<Negotiator[]>([]);
    const [loadTags, setLoadTags] = useState<TagNegoFile[]>([]);
    const [loadNegotiators, setLoadNegotiators] = useState<Negotiator[]>([]);

    const {promiseWithLoading} = usePromiseWithLoadingContext();

    const uploadFiles = useCallback(() => {
        if (files.length <= 0 || tags.length <= 0) {
            return
        }

        promiseWithLoading(NegoApi.addToProcessing({
            fileIds: files.map((el) => el.id),
            tags,
            negotiators
        }))
        .then(() => {
            toast.success('OK');
            updateData();
            rest.onClose && rest.onClose();
        })
        .catch(() => {
            toast.error('Error!!!');
        })
    }, [files, tags, negotiators, rest]);

    useEffect(() => {
        promiseWithLoading(NegoApi.getAllTags())
        .then((data) => {
            setLoadTags(data);
        })
    }, []);

    useEffect(() => {
        promiseWithLoading(NegoApi.getAllNegotiators())
        .then((data) => {
            setLoadNegotiators(data);
        })
    }, []);

    return (
        <ModalWindow 
            className={'add-new-file-modal'}
            {...rest}
        >
            <p className={'add-new-file-modal__title'}>
                Добавить новый файл
            </p>
            <section className={'add-new-file-modal__file-wrapper'}>
                <div className={'add-new-file-modal__files'}>
                    <p className={'add-new-file-modal__subtitle'}>
                        Файлы
                    </p>
                    <AddFileInput
                        files={files}
                        setFiles={(files) => setFiles(() => files)}
                    />
                </div>
                <div className={'add-new-file-modal__tags'}>
                    <p className={'add-new-file-modal__subtitle'}>
                        Теги (минимум 1)
                    </p>
                    <AddTagInput
                        loadTags={loadTags}
                        tags={tags}
                        setTags={(newTags) => setTags(() => newTags)}    
                    />
                </div>
            </section>
            <section className={'add-new-file-modal__spickers-wrapper'}>
                <p className={'add-new-file-modal__subtitle'}>
                    Спикеры (минимум 2)
                </p>
                <SelectSpickers
                    loadNegotiators={loadNegotiators}
                    negotiators={negotiators}
                    setNegotiators={(values: Negotiator[]) => setNegotiators(() => values)}
                />
            </section>
            <div className={'add-new-file-modal__button-wrapper'}>
                <Button
                    buttonType={'primary'}
                    className={'add-new-file-modal__button'}
                    label={'Добавить'}
                    disabled={files.length <= 0 || tags.length <=0 || negotiators.length <= 1}
                    onClick={(uploadFiles)}
                />
            </div>
        </ModalWindow>
    );
}
