import {Table, UnstyledButton} from '@mantine/core';
import {
    IconChevronDown,
    IconChevronUp,
    IconSelector,
} from '@tabler/icons-react';
import './tableHeader.scss';
import clsx from 'clsx';

interface TableHeaderProps {
    children: React.ReactNode;
    reversed: boolean;
    sorted: boolean;
    onSort(): void;
}

export function TableHeader({
    children,
    reversed,
    sorted,
    onSort,
}: TableHeaderProps) {
    const Icon = sorted
        ? reversed
            ? IconChevronUp
            : IconChevronDown
        : IconSelector;
    return (
        <Table.Th 
            className={'table-header'}
        >
            <UnstyledButton
                onClick={onSort}
                className={'table-header__button'}
            >
                <div className={'table-header__data'}>
                    <p className={'table-header__text'}>{children}</p>
                    <div className={'table-header__icon-wrapper'}>
                        <Icon
                            className={'table-header__icon'}
                            stroke={1.5}
                        />
                    </div>
                </div>
            </UnstyledButton>
        </Table.Th>
    );
}
