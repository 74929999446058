import { keyof, Type } from 'io-ts';
import { CollectionDTO } from '../domain/interfaces/collectionDTO';

/**
 * Создания типа enum для io-ts
 */
export function createEnum<E>(e: any, name: string): Type<E> {
    const keys: any = {};
    Object.keys(e)
          .forEach((k: string) => (keys[e[k]] = null));
    return keyof(keys, name) as any;
}

/**
 * Показать ошибку в консоли о несоответствии типов
 */
export function showConsoleError(idEntity: string, classEntity: string, result: any): void {
    if (typeof window === 'undefined') {
        return;
    }
    window.console.log('');
    window.console.log('============== io-ts start error =============');
    window.console.log(`Произошла ошибка при обработке данных, полученных с сервера. Сущность: ${classEntity}, id сущности: ${idEntity}`);
    result.left.forEach((value: any) => {
        let context = '';
        value.context.forEach((ctx: any) => {
            context = `${context}${ctx.key} `;
        });
        window.console.log(`Значение: ${value.value}, key: ${context}`);
    });
    window.console.log('============== io-ts end error =============');
    window.console.log('');
}

/**
 * Проверяем один элемент
 */
export function checkElement<T>(entityType: any, entityClass: any, entity: any): T | null {
    const result: any = entityType.decode(entity);
    if (!!result.left) {
        showConsoleError(entity.id, entity?.name ?? 'Неизвестная модель', result);
        return null;
    }
    return new entityClass(entity);
}

/**
 * Проверяем элементы в массиве
 */
export function checkElementsArray<T>(entityType: any, entityClass: any, array: any[]): T[] {
    return array.map((item: any) => checkElement<T>(entityType, entityClass, item))
                .filter((el: T | null): el is T => !!el);
}

/**
 * Проверяем элементы в CollectionDTO
 * @param entityType
 * @param entityClass
 * @param collection
 */
export function checkElementsCollection<T>(entityType: any, entityClass: any, collection: CollectionDTO<any>): CollectionDTO<T> {
    return {
        items: collection.items.map((item: any) => checkElement<T>(entityType, entityClass, item))
            .filter((el: T | null): el is T => !!el),
        totalCount: collection.totalCount,
    };
}

