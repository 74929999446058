import clsx from 'clsx';
import {ListFilesComponent} from '../../../components/listFilesComponent/listFilesComponent';
import './homeListComponent.scss';
import {useCallback, useMemo, useState} from 'react';
import {RaportApi} from '../../../api/nego/raportApi';
import {usePromiseWithLoadingContext} from '../../../hooks/usePromiseWithLoadingContext';
import {toast} from 'react-toastify';
import NegoFile from '../../../domain/models/negoFile';
import {StatusNegoFileEnum} from '../../../domain/enum/statusNegoFile';
import { TextInput, rem } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { Button } from '../../../components/button/button';

export interface HomeListComponentProps {
    data: NegoFile[];
    openAddDialog: () => void;
}

export function HomeListComponent({data, openAddDialog}: HomeListComponentProps) {
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const [search, setSearch] = useState<string>('');

    const {promiseWithLoading} = usePromiseWithLoadingContext();

    const filterData = useMemo(() => {
        return data.filter(value => value.content?.name.toLowerCase().includes(search.toLowerCase()));
    }, [data, search]);

    const isSelectFile = useCallback(
        (id: string) => {
            return !!selectedFiles.find((value) => value === id);
        },
        [selectedFiles],
    );

    const getDoneElements = useCallback(() => {
        return data.filter((el) => el.status.name === StatusNegoFileEnum.DONE);
    }, [data]);

    const removeSelectFiles = useCallback((id: string) => {
        setSelectedFiles((oldList) => oldList.filter((value) => value !== id));
    }, []);

    const addSelectFiles = useCallback(
        (id: string) => {
            if (isSelectFile(id)) {
                return;
            }

            setSelectedFiles((oldList) => [...oldList, id]);
        },
        [isSelectFile],
    );

    const toggleSelect = useCallback(
        (id: string) => {
            if (
                getDoneElements()
                    .map((value) => value.id)
                    .indexOf(id) === -1
            ) {
                return;
            }
            if (isSelectFile(id)) {
                removeSelectFiles(id);
            } else {
                addSelectFiles(id);
            }
        },
        [addSelectFiles, removeSelectFiles, isSelectFile, getDoneElements],
    );

    const getCommonReport = useCallback(() => {
        if (selectedFiles.length < 2) {
            return;
        }

        promiseWithLoading(RaportApi.getAiIndicators(selectedFiles))
            .then((data) => {
                RaportApi.downloadRaport(data);
            })
            .catch(() => {
                toast.error('Error!');
            });
    }, [selectedFiles]);

    const getAdvancedReport = useCallback(() => {
        if (selectedFiles.length !== 1) {
            return;
        }

        const element = selectedFiles[0];

        promiseWithLoading(RaportApi.getAiSegments([element]))
        .then((data) => {
            RaportApi.downloadRaport(data);
        })
        .catch(() => {
            toast.error('Error!');
        })
    }, [selectedFiles]);

    const createReport = useCallback(() => {
        if (selectedFiles.length === 1) {
            getAdvancedReport();
        } else if (selectedFiles.length >= 2) {
            getCommonReport();
        }
    }, [getAdvancedReport, getCommonReport, selectedFiles]);

    return (
        <section className={clsx('home-list-component')}>
            <div className={clsx('home-list-component__wrapper')}>
                <div className={clsx('home-list-component__header')}>
                    <div className={'home-list-component__buttons'}>
                        <Button
                            buttonType={'primary'}
                            label={'Добавить файлы'}
                            className={'home-list-component__button'}
                            onClick={openAddDialog}
                        />
                        <Button
                            buttonType={'light'}
                            label={selectedFiles.length > 0 ? 
                                selectedFiles.length > 1 ? 
                                    'Создать общий отчёт' : 
                                    'Создать подробный отчёт' : 
                                'Выберите файл'}
                            disabled={selectedFiles.length < 1}
                            className={'home-list-component__button'}
                            onClick={createReport}
                        />
                    </div>
                    <TextInput
                        placeholder='Введите что-нибудь для поиска'
                        mb='md'
                        className={'home-list-component__search'}
                        leftSection={
                            <IconSearch
                                style={{width: rem(16), height: rem(16)}}
                                stroke={1.5}
                            />
                        }
                        value={search}
                        onChange={(event) =>
                            setSearch(event.currentTarget.value)
                        }
                    />
                </div>
                <ListFilesComponent
                    data={filterData}
                    selectFiles={selectedFiles}
                    toggleSelection={toggleSelect}
                />
            </div>
        </section>
    );
}
