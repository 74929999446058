import FileMeta from '../../../../../domain/models/fileMeta';
import { FileInput } from './fileInput/fileInput';
import './addFileInput.scss';

export interface AddFileInputProps {
    files: FileMeta[];
    setFiles: (files: FileMeta[]) => void;
}

export function AddFileInput({ files, setFiles }: AddFileInputProps) {
    return (
        <section className={'add-file-input'}>
            {
                files.map((file, index) => 
                    <FileInput
                        key={index}
                        value={file}
                        onRemove={() => setFiles(files.filter((oldFile) => oldFile !== file))}
                    />
                )
            }
            <FileInput
                onAdd={(file) => setFiles([...files, ...file])}
            />
        </section>
    )
}