import * as t from 'io-ts';

export const FileMetaDtoType = t.interface({
    name: t.string,
    type: t.string,
    id: t.string,
})

export interface FileMetaDto extends t.TypeOf<typeof FileMetaDtoType> {
}

class FileMeta {
    name: string;
    type: string;
    id: string;

    constructor(params: FileMetaDto) {
        this.name = params.name;
        this.type = params.type;
        this.id = params.id;
    }

}

export default FileMeta