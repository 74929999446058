import { useCallback, useEffect, useState } from 'react'
import { usePromiseWithLoadingContext } from '../hooks/usePromiseWithLoadingContext';
import { NegoApi } from '../api/nego/negoApi';
import { toast } from 'react-toastify';
import NegoFile from '../domain/models/negoFile';
import { HomeListComponent } from './components/homeListComponent/homeListComponent';
import { CollectionDTO } from '../domain/interfaces/collectionDTO';
import './homePage.scss';
import { AddNewFileModal } from './components/addNewFileModal/addNewFileModal';

export function HomePage() {
    const {promiseWithLoading} = usePromiseWithLoadingContext();
    const [elements, setElements] = useState<CollectionDTO<NegoFile>>();
    const [isAddModal, setIsAddModal] = useState<boolean>(false);

    const updateData = useCallback(() => {
        promiseWithLoading(NegoApi.getAllFiles())
        .then((data: CollectionDTO<NegoFile>) => {
            setElements(data);
        })
        .catch(() => {
            toast.error('Error!!!');
        });
    }, []);

    useEffect(() => {
        updateData();
    }, [updateData]);

    return (
        <div className={'home-page'}>
            <HomeListComponent
                data={elements?.items ?? []}
                openAddDialog={() => setIsAddModal(true)}
            />
            {
                isAddModal &&
                <AddNewFileModal
                    onClose={() => setIsAddModal(false)}
                    backSideClickEnabled
                    updateData={updateData}
                />
            }
        </div>
    )
}