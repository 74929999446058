import { AppLoader } from '../appLoader/appLoader';
import { Outlet } from 'react-router-dom';
import { Suspense } from 'react';

export function OutletWithLoader() {
    return (
        <Suspense fallback={<AppLoader/>}>
            <Outlet/>
        </Suspense>
    );
}
