import { globalConfig } from '../../config/globalConfig';
import { BaseApi } from '../baseApi';
import { ApiRequestMethodEnum } from '../../domain/enum/apiRequestMethodEnum';
import { RaportFormats } from '../../domain/enum/RaportFormats';
import { RaportType } from '../../domain/enum/RaportType';
import { RaportCreateParams } from './params/raportCreateParams';

export class RaportApi extends BaseApi {
    static DEFAULT_URL = `${globalConfig.api}/nego-files/report/create`;

    static downloadRaport = (data: any, fileFormat: RaportFormats = RaportFormats.XLSX) => {
        let url = URL.createObjectURL(data);

        const a: HTMLAnchorElement = document.createElement('a');
        a.href = url;
        a.download = `report.${fileFormat.toString().toLowerCase()}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    static getAiSegments = (fileId: string[], fileFormat: RaportFormats = RaportFormats.XLSX) => {
        return this.createRaport({
            fileFormat,
            reportType: RaportType.AI_SEGMENTS,
            params: fileId.map((element) => {return {
                name: 'fileId',
                idValue: element,
            }})
        });
    };

    static getAiIndicators = (fileIds: string[], fileFormat: RaportFormats = RaportFormats.XLSX) => {
        return this.createRaport({
            fileFormat,
            reportType: RaportType.AI_INDICATORS,
            params: [{
                name: 'fileIds',
                idValues: fileIds,
            }]
        });
    };

    static createRaport = (params: RaportCreateParams) => {
        return this.apiResource(
            '',
            ApiRequestMethodEnum.POST,
            params,
            {
                responseType: 'blob',
            }
        )
    }
}