import classNames from 'classnames';
import { ReactSvgIcon } from '../../types/reactSvgIcon';

export interface ButtonBaseProps {
    label?: string;
    alignment?: 'left' | 'center';
    buttonType: 'primary' | 'secondary' | 'light';
    styledText?: boolean;
    fillContainer?: boolean;
    shape?: 'rectangle' | 'round';
    Icon?: ReactSvgIcon;
    iconAlignment?: 'left' | 'right';
}

export function useButtonCss<T extends ButtonBaseProps & { className?: string }>(props: T, baseClass: string) {
    const {
        label, className,
        Icon,
        alignment = 'center', iconAlignment = 'left',
        buttonType, styledText, fillContainer,
        shape = 'rectangle',
        ...rest
    } = props;

    const css = classNames(
        baseClass,
        className,
        `${baseClass}--${buttonType}`,
        `${baseClass}--${alignment}`,
        `${baseClass}--icon-${iconAlignment}`,
        `${baseClass}--${shape}`,
        // если у нас только иконка (без текста), то делаем квадрат
        Icon && !label && `${baseClass}--square`,
        fillContainer && `${baseClass}--fill-container`,
        styledText && `${baseClass}--styled-text`,
    );

    return { css, Icon, label, rest };
}
