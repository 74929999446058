import './appLoader.scss';

export function AppLoader() {
    return (
        <div className={'app-loader'}>
            <div className={'lds-ring'}>
                <div/><div/><div/><div/>
            </div>
        </div>
    );
}
