import { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DELAY = 5000;

export function ToastProvider({ children }: { children: ReactNode }) {
    return (
        <>
            {children}
            <ToastContainer
                position={'bottom-right'}
                autoClose={DELAY}
                closeOnClick
                pauseOnHover
            />
        </>
    );
}
