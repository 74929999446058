import { useState } from 'react';
import { SearchSelectAndDefault } from '../../../../../components/searchSelect/searcSelectAndDefault';
import TagNegoFile from '../../../../../domain/models/tagNegoFile';
import { CrossIcon } from '../../../../../asstets/svg';
import './addTagInput.scss';

export interface AddTagInputProps {
    tags: string[];
    setTags: (tags: string[]) => void;
    loadTags: TagNegoFile[];
}

export function AddTagInput({ tags, setTags, loadTags }: AddTagInputProps) {
    const [customTag, setCustomTag] = useState<string>('');

    return (
        <>
            {
                tags.map((el, index) => 
                <div
                    className={'add-tag-input'}
                    key={index}
                >
                    <p className={'add-tag-input__text'}>{el}</p>
                    <CrossIcon
                        onClick={() =>
                            setTags(tags.filter((oldEl) => el !== oldEl))
                        }
                    />
                </div>)
            }
            <SearchSelectAndDefault
                options={
                    customTag ? [...loadTags, {name: customTag}] : loadTags
                }
                getOptionLabel={(model: TagNegoFile) => model.name}
                getOptionValue={(model: TagNegoFile) => model.name}
                onInputChange={setCustomTag}
                onChange={(newValue) =>
                    !!newValue && setTags([...tags, newValue.name])
                }
            />
        </>
    );
}