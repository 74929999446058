import { useCallback, useState } from 'react';
import Negotiator from '../../../../../domain/models/negotiator';
import { SpickerElement } from './spickerElement/spickerElement';
import { SearchSelectAndDefault } from '../../../../../components/searchSelect/searcSelectAndDefault';
import './selectSpickers.scss';

export interface SelectSpickersProps {
    negotiators: Negotiator[],
    loadNegotiators: Negotiator[],
    setNegotiators: (newValue: Negotiator[]) => void,
}

export function SelectSpickers({ negotiators, loadNegotiators, setNegotiators}: SelectSpickersProps) {

    const [customSpiker, setCustomSpicker] = useState<string>('');

    const replaceSpicker = useCallback((replaceIndex: number, newVaule: Negotiator) => {
        setNegotiators(negotiators.map((el, index) => index === replaceIndex ? newVaule : el));
    }, [negotiators]);

    const deleteSpicker = useCallback((deleteIndex: number) => {
        setNegotiators(negotiators.filter((_, index) => index !== deleteIndex));
    }, [negotiators]);

    return (
        <div className={'select-spickers'}>
            {
                negotiators.map((el: Negotiator, index: number) => 
                    <SpickerElement
                        key={index}
                        spicker={el}
                        setSpicker={(newSpicker) => replaceSpicker(index, newSpicker)}
                        deleteSpicker={() => deleteSpicker(index)}
                    />
                )
            }
            <SearchSelectAndDefault
                options={
                    customSpiker ? [...loadNegotiators, {name: customSpiker, result: 0}] : loadNegotiators
                }
                getOptionLabel={(model: Negotiator) => model.name}
                getOptionValue={(model: Negotiator) => model.name}
                onInputChange={setCustomSpicker}
                onChange={(newValue) =>
                    !!newValue && setNegotiators([...negotiators, {name: newValue.name, result: 0}])
                }
            />
        </div>
    )
}