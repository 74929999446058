import { inject, observer } from 'mobx-react';
import { ModalStore } from '../../stores/modalStore';
import { OutletWithLoader } from '../outletWithLoader/outletWithLoader';
import './appWrapper.scss';
import { AppFooter } from './footer/appFooter';
import { AppHeader } from './header/appHeader';
import { useGoToState } from '../../hooks/useGoToState';
import { useEffect } from 'react';

export const AppWrapper = inject('modalStore')(observer(({ modalStore }: { modalStore?: ModalStore }) => {
    const {location} = useGoToState();

    useEffect(() => {
        document.documentElement.setAttribute('lang', location.pathname.startsWith('/ru') ? 'ru' : 'eng');
    },[location.pathname]);

    return (
        <div className={'app-wrapper'}>
            <AppHeader/>
            <div className={'app-wrapper__main-and-footer-wrap'}>
                <main>
                    <OutletWithLoader />
                </main>
                <AppFooter/>
            </div>
            {
                /*
                modalStore?.isShowedAuthModal &&
                <ModalAuth
                    onClose={() => modalStore?.hideAuthModal()}
                />
                
            }
            {
                !!modalStore?.isShowedHasSpecialOfferModal &&
                <HasSpecialOfferModal/>
            }
            {
                !cookiesConfirmed &&
                <CookiesNotification/>
                */
            }
        </div>
    );
}));
