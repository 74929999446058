const variableIsDefined = (variable?: string) => typeof variable !== 'undefined';
//const parseBooleanVariable = (variable?: string) => variableIsDefined(variable) && !!variable && variable !== 'false';
const parseStringVariable = (variable?: string, defaultValue?: string) => variableIsDefined(variable) && variable
    ? variable
    : defaultValue ?? '';
/*
const getBooleanVariableFromConfig = (variable?: string) => {
    let value: boolean;

    try {
        value = parseBooleanVariable(variable);
    } catch (e) {
        value = false;
    }

    return value;
};
*/

const getStringVariableFromConfig = (variable?: string, defaultValue?: string) => {
    let value: string;

    try {
        value = parseStringVariable(variable, defaultValue);
    } catch (e) {
        value = '';
    }

    return value;
};

interface ExtendedWindow {
    GLOBAL_API?: string;
    APP_DOMAIN?: string;
    BASE_PATH?: string;
}

const windowObj = window as ExtendedWindow;

export const globalConfig = {
    api: getStringVariableFromConfig(windowObj.GLOBAL_API, 'https://indicators.ai.inkode.ru/api'),
    appDomain: getStringVariableFromConfig(windowObj.APP_DOMAIN, 'https://indicators.ai.inkode.ru/'),

    basePath: getStringVariableFromConfig(windowObj.BASE_PATH, '/'),
};
