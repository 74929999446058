import clsx from 'clsx';
import Negotiator from '../../../../../../domain/models/negotiator';
import './spickerElement.scss';
import { CrossIcon } from '../../../../../../asstets/svg';

export interface SpickerElementProps {
    spicker: Negotiator,
    setSpicker: (newValue: Negotiator) => void,
    deleteSpicker: (deleteValue: Negotiator) => void;
}

export function SpickerElement({spicker, setSpicker, deleteSpicker}: SpickerElementProps) {
    return (
        <div className={'spicker-element'}>
            <div className='spicker-element__column'>
                <p 
                    className={clsx(
                        'spicker-element__text--gray',
                        'spicker-element__text'
                    )}
                >
                    Имя
                </p>
                <p className={'spicker-element__text'}>
                    {spicker.name}
                </p>
            </div>
            <div className={'spicker-element__end-wrapper'}>
                <div className='spicker-element__column'>
                    <p 
                        className={clsx(
                            'spicker-element__text--gray',
                            'spicker-element__text'
                        )}
                    >
                        Результат
                    </p>
                    <input
                        className={clsx(
                            'spicker-element__input',
                            'spicker-element__text'
                        )}
                        value={`${spicker.result}`}
                        type={'number'}
                        min={0}
                        onChange={({target}) => setSpicker({name: spicker.name, result: !!target.value ? Number.parseInt(target.value, 10) ?? 0 : 0})}
                    />
                </div>
                <div className='spicker-element__delete-button'>
                    <CrossIcon
                        onClick={() => deleteSpicker(spicker)}
                    />
                </div>
            </div>
        </div>
    );
}