import axios, { AxiosError } from 'axios';
//import { AuthService } from '../domain/auth/storage/authService';
import { globalConfig } from './globalConfig';

const api = axios.create({ baseURL: globalConfig.api });

api.defaults.headers.post['Content-Type'] = 'application/json';
/*
const UNAUTHORIZED_STATUS_CODE = 401;

const basicAuthEndpoints = [
    'authorization',
    'rent-request/user-cost/request',
    'rent-request/create',
];

const excludeBasicAuthEndpoints: string[] = [
];

api.interceptors.request.use(config => {
    if (!config.headers) config.headers = {} as AxiosRequestHeaders;

    const userInfo = AuthService.getUserInfo();
    const accessToken = userInfo?.access_token ?? null;

    const needBasicAuth = basicAuthEndpoints.some(endpoint => config.url?.includes(endpoint)) &&
        excludeBasicAuthEndpoints.every(endpoint => !config.url?.includes(endpoint));

    if (config.headers['Authorization']) return config;

    if (accessToken || needBasicAuth) {
        config.headers['Authorization'] = needBasicAuth
            ? 'Basic Y2xpZW50OmIzMjUxNzk1LTVkNmEtNDdhZS04NjY3LTBhMjY4MjQ2ZTMzYQ=='
            : `Bearer ${accessToken}`;
    }

    return config;
});

*/
function handleError(error: AxiosError) {
    console.log('error', error)

    /*
    if (error?.response?.status === UNAUTHORIZED_STATUS_CODE) {
        AuthService.logOut();
    }
    */
    // если это была ошибка не авторизации - просто возвращаем её
    return Promise.reject(error);
}

api.interceptors.response.use(response => response, error => handleError(error));

export { api };
