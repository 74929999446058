import { createContext, ReactNode, useContext, useState } from 'react';
import { AppLoader } from '../components/appLoader/appLoader';

export interface PromiseWithLoadingContext {
    isLoading: boolean;
    promiseWithLoading: (action: Promise<any>) => Promise<any>;
    wrapInPromiseWithLoading: (action: () => any) => Promise<any>;
}

const promiseWithLoadingContext = createContext<PromiseWithLoadingContext | null>(null);

export function usePromiseWithLoadingContext() {
    const context = useContext(promiseWithLoadingContext);
    if (!context) throw new Error('usePromiseWithLoading can only be used inside ');
    return context;
}

export interface PromiseWithLoadingProviderProps {
    children: ReactNode;
}

export function PromiseWithLoadingProvider(props: PromiseWithLoadingProviderProps) {

    const [ isLoading, setIsLoading ] = useState(false);

    function promiseWithLoading(action: Promise<any>) {
        setIsLoading(true);
        return action
            .finally(() => setIsLoading(false));
    }

    function wrapInPromiseWithLoading(action: () => any) {
        setIsLoading(true);
        return new Promise(resolve => {
            const res = action();
            resolve(res);
        })
            .finally(() => setIsLoading(false));
    }

    return (
        <promiseWithLoadingContext.Provider
          value={{ isLoading, promiseWithLoading, wrapInPromiseWithLoading }}
        >
            {
                isLoading &&
                <AppLoader/>
            }
            {props.children}
        </promiseWithLoadingContext.Provider>
    );
}
