import * as t from 'io-ts';
import FileMeta, { FileMetaDtoType } from './fileMeta';
import { STATUS_NEGO_FILE, StatusNegoFileEnumDtoType } from '../enum/statusNegoFile';
import { NameValuePair } from '../../types/nameValuePair';
import Negotiator, { NegotiatorDtoType } from './negotiator';

export const NegoFileDtoType = t.interface({
    id: t.string,
    content: t.union([FileMetaDtoType, t.null]),
    duration: t.number,
    createDate: t.string,
    tags: t.array(t.string),
    status: t.union([StatusNegoFileEnumDtoType, t.null]),
    processingRemainingTime: t.number,
    negotiators: t.array(NegotiatorDtoType),
})

export interface NegoFileDto extends t.TypeOf<typeof NegoFileDtoType> {
}

class NegoFile {
    id: string;
    content: FileMeta | null;
    duration: number;
    createDate: string;
    tags: string[];
    status: NameValuePair;
    processingRemainingTime: number;
    negotiators: Negotiator[];

    constructor(params: NegoFileDto) {
        this.id = params.id;
        this.content = params.content;
        this.duration = params.duration;
        this.createDate = params.createDate;
        this.tags = params.tags;
        this.status = params.status ? STATUS_NEGO_FILE[params.status] : STATUS_NEGO_FILE.NO_STATUS;
        this.processingRemainingTime = params.processingRemainingTime;
        this.negotiators = params.negotiators;
    }

}

export default NegoFile