import * as t from 'io-ts';

export const NegotiatorDtoType = t.interface({
    name: t.string,
    result: t.number,
})

export interface NegotiatorDto extends t.TypeOf<typeof NegotiatorDtoType> {
}

class Negotiator {
    name: string;
    result: number

    constructor(params: NegotiatorDto) {
        this.name = params.name;
        this.result = params.result;
    }

}

export default Negotiator