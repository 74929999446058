import {Provider as StoreProvider} from 'mobx-react';
import {modalStore} from './stores/modalStore';
import {ToastProvider} from './hooks/context/toastProvider';
import {PromiseWithLoadingProvider} from './hooks/usePromiseWithLoadingContext';
import {AppRoutes} from './routes/appRoutes';
import {BrowserRouter} from 'react-router-dom';
import './styles/app.scss';
import {globalConfig} from './config/globalConfig';
import { StyleImports } from './styles/styleImports';
import { MantineProvider, createTheme } from '@mantine/core';

const stores = {modalStore};
const theme = createTheme({})

function App() {
    return (
        <MantineProvider
            theme={theme}
        >
        <ToastProvider>
            <StoreProvider {...stores}>
                <StyleImports/>
                <BrowserRouter basename={globalConfig.basePath}>
                    <PromiseWithLoadingProvider>
                        <AppRoutes />
                    </PromiseWithLoadingProvider>
                </BrowserRouter>
            </StoreProvider>
        </ToastProvider>
        </MantineProvider>
    );
}

export default App;
