import * as t from 'io-ts';

export const TagNegoFileDtoType = t.interface({
    name: t.string,
})

export interface TagNegoFileDto extends t.TypeOf<typeof TagNegoFileDtoType> {
}

class TagNegoFile {
    name: string;

    constructor(params: TagNegoFileDto) {
        this.name = params.name;
    }

}

export default TagNegoFile