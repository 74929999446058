import clsx from 'clsx';
import { useMemo } from 'react';
import './modalWindow.scss';
import { CrossIcon } from '../../asstets/svg';

export interface ModalWindowProps {
    children?: JSX.Element | JSX.Element[];
    header?: string;
    onClose?: () => void;
    backSideClickEnabled?: boolean;
    className?: string;
}

export function ModalWindow({ children, onClose, className, backSideClickEnabled }: ModalWindowProps) {
    const onBackSideClickClose = useMemo(() => {
        return backSideClickEnabled && onClose
            ? onClose
            : undefined;
    }, [backSideClickEnabled, onClose]);

    return <div
        className={clsx(className, 'modal-window-wrapper')}
        onClick={onBackSideClickClose}
    >
        <div
            className={'modal-window'}
            onClick={e => e.stopPropagation()}
        >
            {
                onClose &&
                <CrossIcon
                    className={clsx('pointer', 'modal-window__close-button')}
                    onClick={onClose}
                />
            }
            {children}
        </div>
    </div>;
}
