import { makeAutoObservable } from 'mobx';

export class ModalStore {

    constructor() {
        makeAutoObservable(this);
    }

}

export const modalStore = new ModalStore();