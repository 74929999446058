import { toast } from 'react-toastify';
import { FileStorageApi } from '../../../../../../api/fileStorage/fileStorageApi';
import { CrossIcon, PlusIcon } from '../../../../../../asstets/svg';
import FileMeta from '../../../../../../domain/models/fileMeta';
import { useDropZone } from '../../../../../../hooks/useDropZone';
import { usePromiseWithLoadingContext } from '../../../../../../hooks/usePromiseWithLoadingContext';
import './fileInput.scss';
import { useCallback } from 'react';

export interface FileInputProps {
    value?: FileMeta;
    onAdd?: (file: FileMeta[]) => void;
    onRemove?: () => void;
}

export function FileInput({value, onAdd, onRemove}: FileInputProps) {
    const {promiseWithLoading} = usePromiseWithLoadingContext();

    const uploadFile = useCallback ((files: File[]) => {
        if (files.length <= 0 || !onAdd) {
            return;
        }
        promiseWithLoading(FileStorageApi.upload(files[0]))
        .then((data) => {
            onAdd(data);
        })
        .catch(() => {
            toast.error('Error!!!');
        })
    }, []);

    const {listeners} = useDropZone({
        handleDrop: uploadFile,
        filterDrop: false,
    });
    return (
        <div 
            className={'file-input'}
        >
            {
                !!value &&
                <div className={'file-input__desc-file'}>
                    <p className={'file-input__text'}>
                        {
                            value.name
                        }
                    </p>
                    <CrossIcon
                        onClick={onRemove}
                    />
                </div>
            }
            {
                !value &&
                <div 
                    className={'file-input__drop-file'}
                    {...listeners}
                >
                    <PlusIcon/>
                </div>
            }
        </div>
    );
}